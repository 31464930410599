import type {
    ApiError,
    BusinessCoupon,
    BusinessesInstantSearchData, BusinessesSearchData,
    BusinessReportType,
    BusinessReview, ClassifiedAdSearchData,
    LikedBusiness,
    MainBusinessesData, MainBusinessesHomeData,
    MainSingleBusinessData,
} from "~/types";

export const useMainBusinessesDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;


    const fetchMainBusiness = async () => {
        const {data, error} = await useAsyncData(`business-${route.params.slug}`,
            () => nuxtApp.$getBusinessBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainSingleBusinessData;
        }
    }


    const fetchCurrentBusinessCoupon = async () => {
        const {data, error} = await useAsyncData(`business-coupon-${route.params.id}`,
            () => nuxtApp.$getBusinessCouponById(parseInt(route.params.id as string))
        );
        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as BusinessCoupon;
        }
    }

    const fetchMainBusinessesData = async () => {
        const { data, error, refresh } = await useAsyncData(`main-businesses`,
            () => useNuxtApp().$getBusinessesDataFromUrl(`${baseUrl}/businesses`)
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(()=>{
                return {
                    business_page:{
                        businesses: data.value.data,
                        page_number: data.value.meta.current_page,
                    },
                    pagination_meta_data: data.value.meta,
                    pagination_links: data.value.links,
                } as MainBusinessesData
            });
        }
    };

    const fetchMainBusinessesDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getBusinessesDataFromUrl(url);
            return  {
                business_page:{
                    businesses: response.data,
                    page_number: response.meta.current_page,
                },
                pagination_meta_data: response.meta,
                pagination_links: response.links,
            } as MainBusinessesData;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchBusinessReportTypes = async () => {
        const {data, error} = await useAsyncData('business-report-types',
            () => nuxtApp.$getBusinessReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as BusinessReportType[];
        }
    }

    const requestToCreateNewBusinessReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createBusinessReport(data);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToAvailBusinessCoupon = async (couponId:string, availCouponForm:object) => {
        try {
            const response = await nuxtApp.$availBusinessCouponByCouponId(
                useRoute().params.slug as string,
                parseInt(couponId),
                availCouponForm,
            );
            return response.data as BusinessCoupon;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToCreateNewBusinessReview = async (newBusinessReviewForm:object) => {
        try {
            const response = await nuxtApp.$createMyGivenBusinessReviewsAndRatings(
                newBusinessReviewForm,
            );
            return response.data as BusinessReview;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToLikeABusiness = async () => {
        try {
            const response = await nuxtApp.$likeBusinessBySlug(route.params.slug as string);
            return response.data as LikedBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToLikeABusinessBySlug = async (slug:string) => {
        try {
            const response = await nuxtApp.$likeBusinessBySlug(slug);
            return response.data as LikedBusiness;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }
    
    
    const fetchInstantSearchResults = async (query:string) => {
        try {
            const response = await nuxtApp.$getInstantSearchResultsForBusinessesByQuery(query)
            console.log(response)
            return response as BusinessesInstantSearchData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchBusinessesHomeData = async()=>{
        const {data, error} = await useAsyncData(`business-home`,
            () => nuxtApp.$getBusinessesHomeData()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainBusinessesHomeData;
        }
    }

    const fetchBusinessesBySearchCriteria = async(query:string)=>{
        try{
            const response = await nuxtApp.$getBusinessesBySearchCriteria(query);
            return response as BusinessesSearchData;
        }
        catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return{
        fetchMainBusiness,
        fetchMainBusinessesData,
        fetchMainBusinessesDataFromUrl,
        fetchBusinessReportTypes,
        fetchCurrentBusinessCoupon,

        requestToCreateNewBusinessReport,
        requestToAvailBusinessCoupon,
        requestToLikeABusiness,
        requestToLikeABusinessBySlug,
        fetchInstantSearchResults,
        requestToCreateNewBusinessReview,

        fetchBusinessesHomeData,
        fetchBusinessesBySearchCriteria
    }
}