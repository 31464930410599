import {useMainBusinessesDataService} from "~/composables/useMainBusinessesDataService";
import type {BusinessesInstantSearchData} from "~/types";

export const useMainBusinessesDataManager = () => {
    const mainBusinessesDataService = useMainBusinessesDataService();
    const businessesStore = useBusinesses();

    const getMainBusiness = async () => {
        return await mainBusinessesDataService.fetchMainBusiness();
    }

    const getMainBusinesses = async () => {
        const results = await mainBusinessesDataService.fetchMainBusinessesData();
        return results?.value;
    }

    const getMainBusinessesFromUrl = async (url: string) => {
        return await mainBusinessesDataService.fetchMainBusinessesDataFromUrl(url);
    }

    const getBusinessReportTypes = async () => {
        const storedBusinessReportTypes = computed(() => {
            return businessesStore.business_report_types;
        })
        if (storedBusinessReportTypes.value == null || storedBusinessReportTypes.value.length == 0 || !businessesStore.isBusinessReportTypesValid()) {
            const businessReportTypes =  await mainBusinessesDataService.fetchBusinessReportTypes();
            if(businessReportTypes)
                businessesStore.setBusinessReportTypes(businessReportTypes);
        }

        return storedBusinessReportTypes;
    }

    const getCurrentBusinessCoupon = async () => {
        return await mainBusinessesDataService.fetchCurrentBusinessCoupon();
    }

    const reportBusiness = async (data:object) => {
        const createdBusinessReport = await mainBusinessesDataService.requestToCreateNewBusinessReport(data);
        if (createdBusinessReport) {
            useAlertSetter().setSuccessAlert("Business Reported Successfully");
            return createdBusinessReport;
        }
        return null;
    }

    const availBusinessCoupon = async (couponId:string,formData:object) => {
        const availedBusinessCoupon = await mainBusinessesDataService.requestToAvailBusinessCoupon(couponId,formData);
        if (availedBusinessCoupon) {
            useAlertSetter().setSuccessAlert("Successfully generated and sent coupon on your email address!");
        }
    }

    const reviewBusiness = async (formData:object) => {
        const businessReview = await mainBusinessesDataService.requestToCreateNewBusinessReview(formData);
        if (businessReview) {
            useAlertSetter().setSuccessAlert("Business Reviewed Successfully");
            return businessReview;
        }
        return null;
    }

    const likeBusiness = async () => {
        const likedBusiness = await mainBusinessesDataService.requestToLikeABusiness();
        if (likedBusiness) {
            useAlertSetter().setSuccessAlert("Business Liked Successfully");
            return likedBusiness
        }
        return null;
    }

    const likeBusinessBySlug = async (slug:string) => {
        const likedBusiness = await mainBusinessesDataService.requestToLikeABusinessBySlug(slug);
        if (likedBusiness) {
            useAlertSetter().setSuccessAlert("Business Liked Successfully");
            return likedBusiness
        }
        return null;
    }

    const getBusinessesHome = async()=>{
        const homeData = await mainBusinessesDataService.fetchBusinessesHomeData();
        if(homeData){
            return homeData;
        }
        return null;
    }

    const getBusinessesInstantSearchResults = async (query:string) => {
        return await mainBusinessesDataService.fetchInstantSearchResults(query) as BusinessesInstantSearchData;
    }

    const searchBusinessesByCriteria = async (query:string)=>{
        const searchResults =  await mainBusinessesDataService.fetchBusinessesBySearchCriteria(query);
        if(searchResults){
            return searchResults
        } else{
            return null;
        }
    }

    return {
        getMainBusiness,
        getMainBusinesses,
        getMainBusinessesFromUrl,
        getBusinessReportTypes,
        getCurrentBusinessCoupon,
        reportBusiness,
        availBusinessCoupon,
        reviewBusiness,
        likeBusiness,
        likeBusinessBySlug,
        getBusinessesInstantSearchResults,

        getBusinessesHome,
        searchBusinessesByCriteria
    }
}